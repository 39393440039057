
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Cumulative Progress Report',
  components: {
    axios,
    Form,
    Datatable,
    SearchBar,
    Multiselect,
    Swal,
  },
  data() {
    return {
      api_url: '',
      allData: false,
      enrollData: false,
      assesmentData: false,
      certificationData: false,
      jobPlaceData: false,

      report_type: '' as any,
      load: false,
      loading: false,
      tranche: [] as any,
      TpartnerData: [] as any,
      TcategoryData: [] as any,
      TranceData: [] as any,
      optionsTPartner: [] as any,
      optionsTCategory: [] as any,
      optionsTranche: [] as any,
      btnCheck: false,
      TpartnerInfos: [] as any,
      TCategoryInfos: [] as any,
      TrancheInfos: [] as any,
      start_date: '' as any,
      end_date: '' as any,

      showTrainningStatistics: false,
      componentKey: 0,
      actionActive: false,
      categoryReportInfo: [] as any,

      FinalTotalTarget: 0 as any,
      FinalTotalEnrollment: 0 as any,
      FinalTotalmaleEnrollment: 0 as any,
      FinalTotalfemaleEnrollment: 0 as any,

      FinalTotalCertified: 0 as any,
      FinalTotalmaleCertified: 0 as any,
      FinalTotalFemaleCertified: 0 as any,

      FinalTotalAssesment: 0 as any,
      FinalTotalmaleAssesment: 0 as any,
      FinalTotalFemaleAssesment: 0 as any,

      FinalTotalJob: 0 as any,
      FinalTotalmaleJob: 0 as any,
      FinalTotalFemaleJob: 0 as any,
    };
  },
  async created() {
    await this.getAssociation();
    await this.getTranche();
    await this.getCategory();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

    formData.set('entity', this.TpartnerData);
      formData.set('category', this.TcategoryData);
      formData.set('tranche', this.TranceData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      formData.set('training_stage', this.report_type);
      let data = `${this.VUE_APP_API_URL}/api/report/category-course-reportprint`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },

    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

     formData.set('entity', this.TpartnerData);
      formData.set('category', this.TcategoryData);
      formData.set('tranche', this.TranceData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      formData.set('training_stage', this.report_type);

      await ApiService.post('report/category-course-reportpdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      let entity_id = this.TpartnerData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      formData.set('entity', this.TpartnerData);
      formData.set('category', this.TcategoryData);
      formData.set('tranche', this.TranceData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      formData.set('training_stage', this.report_type);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/category-course-reportExport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'course-category-report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {});
    },
    async getCategory() {
      this.load = true;
      await ApiService.get('configurations/category_type/list')
        .then((response) => {
          this.TCategoryInfos = response.data.data;
          this.TCategoryInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.category_name,
            };
            this.optionsTCategory.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {});
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async categoryReport() {
      if (this.report_type == 1) {
        this.enrollData = true;
        this.assesmentData = false;
        this.certificationData = false;
        this.jobPlaceData = false;
        this.allData = false;
      } else if (this.report_type == 2) {
        this.assesmentData = true;
        this.enrollData = false;
        this.certificationData = false;
        this.jobPlaceData = false;
        this.allData = false;
      } else if (this.report_type == 3) {
        this.certificationData = true;
        this.enrollData = false;
        this.assesmentData = false;
        this.jobPlaceData = false;
        this.allData = false;
      } else if (this.report_type == 4) {
        this.jobPlaceData = true;

        this.enrollData = false;
        this.assesmentData = false;
        this.certificationData = false;
        this.allData = false;
      } else {
        this.allData = true;

        this.enrollData = false;
        this.assesmentData = false;
        this.certificationData = false;
        this.jobPlaceData = false;
      }
      let entity_id = this.TpartnerData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      this.showTrainningStatistics = true;

      await ApiService.get(
        'report/category-course-report?entity=' +
          entity_id +
          '&category=' +
          this.TcategoryData +
          '&tranche=' +
          this.TranceData +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date +
          '&training_stage=' +
          this.report_type
      )
        .then((response) => {
          this.categoryReportInfo = response.data.data.categories_data;

          this.FinalTotalTarget = response.data.data.FinalTotalTarget;

          if (this.report_type == 1) {
            this.FinalTotalEnrollment = response.data.data.FinalTotalEnrollment;
            this.FinalTotalmaleEnrollment =
              response.data.data.FinalTotalmaleEnrollment;
            this.FinalTotalfemaleEnrollment =
              response.data.data.FinalTotalfemaleEnrollment;
          }
          if (this.report_type == 2) {
            this.FinalTotalAssesment = response.data.data.FinalTotalAssesment;
            this.FinalTotalmaleAssesment =
              response.data.data.FinalTotalmaleAssesment;
            this.FinalTotalFemaleAssesment =
              response.data.data.FinalTotalFemaleAssesment;
          }
          if (this.report_type == 3) {
            this.FinalTotalCertified = response.data.data.FinalTotalCertified;
            this.FinalTotalmaleCertified =
              response.data.data.FinalTotalmaleCertified;
            this.FinalTotalFemaleCertified =
              response.data.data.FinalTotalFemaleCertified;
          }
          if (this.report_type == 4) {
            this.FinalTotalJob = response.data.data.FinalTotalJob;
            this.FinalTotalmaleJob = response.data.data.FinalTotalmaleJob;
            this.FinalTotalFemaleJob = response.data.data.FinalTotalFemaleJob;
          } else {
            this.FinalTotalEnrollment = response.data.data.FinalTotalEnrollment;
            this.FinalTotalmaleEnrollment =
              response.data.data.FinalTotalmaleEnrollment;
            this.FinalTotalfemaleEnrollment =
              response.data.data.FinalTotalfemaleEnrollment;

            this.FinalTotalAssesment = response.data.data.FinalTotalAssesment;
            this.FinalTotalmaleAssesment =
              response.data.data.FinalTotalmaleAssesment;
            this.FinalTotalFemaleAssesment =
              response.data.data.FinalTotalFemaleAssesment;

            this.FinalTotalCertified = response.data.data.FinalTotalCertified;
            this.FinalTotalmaleCertified =
              response.data.data.FinalTotalmaleCertified;
            this.FinalTotalFemaleCertified =
              response.data.data.FinalTotalFemaleCertified;

            this.FinalTotalJob = response.data.data.FinalTotalJob;
            this.FinalTotalmaleJob = response.data.data.FinalTotalmaleJob;
            this.FinalTotalFemaleJob = response.data.data.FinalTotalFemaleJob;
          }

          this.showTrainningStatistics = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
